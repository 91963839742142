<template>
  <div class="cate">
    <div class="option">
      <div class="title">装机必备</div>
      <div class="op_tol">
        <div class="op_item" @click="smallNavClick(index, item.value)" :class="[currentSmallIndex === index ? 'act' :'']"
             v-for="(item, index) in smallNav">{{item.name}}
        </div>
      </div>
    </div>
    <el-row :gutter="20" class="cate_content">
      <!-- -->
      <el-col :xs="10" :sm="10" :md="10" :lg="8" :xl="8" v-for="(item, index) in dataList" :key="item.id">
        <Item  :time="item.createTime"  :currentPage="currentPage" :orderType="orderType"
              :name="item.name" :star="item.score"  :softId="item.id" :size="item.appSize" :iconUrl="item.logoUrl" :type="item.categoryName"/>
      </el-col>

    </el-row>
    <!--<div class="cate_content">
    </div>-->
    <div class="page_">
      <el-pagination
              @current-change="currentChange"
              layout="prev, pager, next"
              :page-size="pageSize"
              :current-page="currentPage"
              :total="total">
      </el-pagination>
    </div>


  </div>
</template>

<script>
  import Item from '@/components/item/Item.vue'
  import {getInstallSoft} from '@/utils/network'

  export default {
    data() {
      return {
        //分页
        currentPage:this.$store.state.isLastPage?parseInt(this.$store.state.lastPage):1,
        pageSize:12,
        total:null,
        dataList: [],
        orderType:this.$store.state.isLastPage?(this.$store.state.orderType):'defalut',
        currentSmallIndex: this.$store.state.isLastPage?this.$store.state.orderType!='defalut'?this.$store.state.orderType==='createtime'?1:2:0:0,
        smallNav: [{name:'更新时间', value:'defalut'},
          {name:'下载量', value: 'createtime'},
          {name:'评分', value: 'appscore'},]
      }
    },
    created() {
      this.getData()
      this.$store.dispatch('isLastPage', {
        isLastPage: false
      })
      this.$store.dispatch('orderType', {
        orderType: 'defalut'
      })
    },
    methods: {
      //分页的切换数据
      currentChange(index) {
        this.currentPage = index
        this.getData()
        window.scrollTo(0, 0);

      },
      smallNavClick(index, orderType ) {
        this.currentSmallIndex = index
        this.orderType = orderType
        this.currentPage = 1
        this.getData()
      },
      async getData() {
        const {data: res} = await getInstallSoft({
          orderType:this.orderType,
          page: this.currentPage,
          per_page: this.pageSize,
          id: 10,
          sourceType:'web'
        })
        if (res.code !== 200) return this.$message.error('请求失败')
        this.dataList = res.data.vodata
        this.total = res.data.total
        // this.$message.success('获取成功')
      }
    },
    components: {
      Item
    }
  }
</script>

<style lang="less" scoped>
  .cate {
    width: 80%;
    min-height: 670px;
    margin: 20px auto;
    background-color: #fff;
    overflow: hidden;
    padding: 0 12px;

    .option {
      display: flex;
      //margin: 10px;
      height: 40px;
      line-height: 40px;
      justify-content: space-between;

      .title {
        font-weight: 700;
        font-size: 18px;
      }

      .op_tol {
        display: flex;
        width: 180px;
        justify-content: space-around;
        font-size: 14px;
        color: rgba(0, 0, 0, .6);
        .act {
          color: red;
        }
      }
    }

    .cate_content {
      //display: flex;
      //justify-content: space-around;
      flex-wrap: wrap;
      margin-bottom: 20px;
      min-height: 525px;
    }

  }

</style>
